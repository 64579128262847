<script>
import Spinner from "@/components/ui/Spinner";
import VitalBox from "@/components/patient/dashboard/VitalBox";
import AlertBox from "@/components/patient/dashboard/AlertBox";
import ActivityAlertBox from "@/components/patient/dashboard/ActivityAlertBox";
import FormBox from "@/components/patient/dashboard/FormBox";
import AppointmentBox from "@/components/patient/dashboard/AppointmentBox";
import IndicationBox from "@/components/patient/dashboard/IndicationBox";
import AttentionBox from "@/components/patient/dashboard/AttentionBox";
import NotesBox from "@/components/patient/dashboard/NotesBox";
import configService from "@/services/config";
import patientService from "@/services/patient";
import alertsService from "@/services/alerts";
import segmentService from "@/services/segment";
import ModalTaskGraph from "@/components/patient/dashboard/ModalTaskGraph";
import ModalPatientTasksList from "@/components/patient/dashboard/ModalPatientTasksList";
import ModalManualAddTask from "@/components/patient/dashboard/ModalManualAddTask";
import ModalPatientMap from "@/components/patient/dashboard/ModalPatientMap";
import ModalTaskDetail from "@/components/patient/dashboard/ModalTaskDetail";
import ModalOpenEpisode from "@/components/patient/dashboard/ModalOpenEpisode";
import ModalGroup from "@/components/patient/dashboard/ModalGroup";
import ModalPatientEcgTable from "@/components/patient/dashboard/ModalPatientEcgTable";
import PatientLabels from "@/components/patient/dashboard/PatientLabels";
import DoctorsDetail from "@/components/ui/DoctorsDetail";
import episodeService from "@/services/episode";
import { mapGetters } from "vuex";
import {
  enableEpisodeCreation,
  isIndicationsEnabled,
  isVideoRoomEnabled,
} from "@/services/config";
import appointmentService from "@/services/appointment";

export default {
  name: "PatientDashboard",

  components: {
    Spinner,
    VitalBox,
    AlertBox,
    ActivityAlertBox,
    AttentionBox,
    AppointmentBox,
    ModalTaskGraph,
    ModalPatientTasksList,
    ModalManualAddTask,
    ModalPatientMap,
    ModalTaskDetail,
    ModalOpenEpisode,
    ModalGroup,
    ModalPatientEcgTable,
    PatientLabels,
    FormBox,
    IndicationBox,
    NotesBox,
    DoctorsDetail,
  },

  data() {
    return {
      modal: "",
      selectedType: "",
      selectedTask: null,
      alertsLimit: 6,
      patientId: this.$route.params.id,
      patient: {},
      publicGroup: [],
      isLoading: false,
      alerts: [],
      appointments: [],
      vitals: [],
      activityAlerts: [],
      activeEpisode: null,
      showDialogModal: false,
      enableEpisodeCreation,
      key: this.$route.path,
      isSalesEnabled: configService.theme === "albya",
      isIndicationsEnabled,
      isVideoRoomEnabled,
    };
  },

  created() {
    segmentService.track("Patient Dashboard");
    this.loadInitialData();

    this.$bus.$on("episode-closed", () => {
      this.activeEpisode = null;
    });
  },

  computed: {
    ...mapGetters(["isAdmin", "isAssistant", "isDoctor"]),

    patientGender() {
      switch (this.patient.gender) {
        case "m":
          return "Hombre";

        case "f":
          return "Mujer";

        default:
          return "Otro";
      }
    },
  },

  methods: {
    loadInitialData() {
      this.isLoading = true;

      const query = {
        scheduledAt: { $lte: new Date() },
      };

      Promise.all([
        patientService.getById(this.patientId),
        alertsService.getAllAlerts({
          patientID: this.patientId,
          query,
        }),
        episodeService.getActive(this.patientId),
        appointmentService.getAppointments({
          patient: this.patientId,
          populate: "doctor",
        }),
      ])
        .then(([patient, alerts, activeEpisode, appointments]) => {
          this.patient = patient;
          this.activityAlerts = alertsService.getActivityAlerts({ alerts });
          const { vitals, rest } = alertsService.separateAlerts({ alerts });
          this.vitals = alertsService.groupBy({
            alerts: vitals,
            limit: this.alertsLimit,
          });
          this.alerts = alertsService.groupBy({
            alerts: rest,
            limit: this.alertsLimit,
            prop: "type",
          });
          this.activeEpisode = activeEpisode ? activeEpisode : null;
          this.appointments = appointments.docs;
        })
        .then(async () => {
          if (this.patient.patient.publicGroupId) {
            this.publicGroup = await patientService.getPublicGroupById(
              this.patient.patient.publicGroupId
            );
          }
        })
        .finally(() => (this.isLoading = false));
    },

    showModal({ modal, task, type }) {
      this.modal = modal;
      this.selectedTask = task;
      this.selectedType = type;
      this.showDialogModal = true;
    },

    closeModal() {
      this.modal = "";
      this.selectedType = null;
      this.selectedTask = null;
      this.showDialogModal = false;
    },

    onSaveManualTask(task) {
      this.closeModal();
      this.vitals[task.name].unshift(task);
      if (this.vitals[task.name].length > this.alertsLimit) {
        this.vitals[task.name].pop();
      }
    },

    async openEpisode(doctor) {
      try {
        const createdEpisode = await episodeService.create(this.patientId, {
          doctor,
        });
        this.activeEpisode = createdEpisode;
        this.closeModal();
      } catch (error) {
        //
      }
    },

    async goToPatientVideoRoom() {
      this.$router.push({
        name: "patient-videoroom",
        params: { id: this.patientId },
      });
    },

    getIMC(weight, height) {
      return (weight / (height / 100) ** 2).toFixed(1);
    },
  },

  watch: {
    "$route.params.id": {
      handler: function () {
        this.patientId = this.$route.params.id;
        this.loadInitialData();
      },
      immediate: true,
    },
  },
};
</script>

<template lang="pug">
  section.patient-dashboard
    spinner(v-if="isLoading")
    .data(v-else)
      .headline
        .patient-header
          h2 {{ patient.firstName | capitalize }} {{ patient.lastName | capitalize }}
          .patient-details
            .headline__details
              .detail-item(v-if="isVideoRoomEnabled && isDoctor")
                .button.button--blue(@click="goToPatientVideoRoom()")
                  micon.button__icon(name="call")
                  span.button__text Videollamada
              .detail-item
                micon(color="grey" :name="this.patient.gender === 'w' ? 'woman' : 'man'")
                | {{ patientGender }}
                pre(v-if="patient.bornAt")  de {{ patient.bornAt | age }}
              .detail-item(
                v-if="patient.patient.medicalInsurance && patient.patient.medicalInsurance.number"
              )
                micon(color="grey" name="id_card")
                | Nro. socio: {{ patient.patient.medicalInsurance.number }}
              .detail-item(
                v-if="patient.governmentId && patient.governmentId.number"
              )
                micon(color="grey" name="dni")
                | Nro. documento: {{ patient.governmentId.number }}
              .detail-item(v-if="patient.patient && patient.patient.health && patient.patient.health.weight")
                micon(color="grey" name="weight")
                | {{ patient.patient.health.weight }} kgs {{ patient.patient.health.height && `/ IMC: ${getIMC(patient.patient.health.weight,patient.patient.health.height)}`}}
              .detail-item(v-if="patient.patient && patient.patient.health && patient.patient.health.height")
                micon(color="grey" name="human-male-height")
                | {{ patient.patient.health.height }} cms
              .detail-item(v-if="patient.doctors && patient.doctors.length")
                micon(color="grey" name="medical_services")
                doctors-detail(:doctors="patient.doctors")
                //- | {{ patient.doctors[0].firstName | capitalize }} {{ patient.doctors[0].lastName | capitalize }}
            .headline__details
              .detail-item(v-if="patient.email")
                micon(color="grey" name="email")
                a(:href="`mailto:${patient.email}`") {{ patient.email }}
              .detail-item(v-if="patient.phone")
                micon(color="grey" name="call")
                a(:href="`tel:${patient.phone}`") {{ patient.phone }}
              .detail-item(v-if="patient.secondaryPhone")
                micon(color="grey" name="smartphone")
                a(:href="`tel:${patient.secondaryPhone}`") {{ patient.secondaryPhone }}
              .detail-item(v-if="patient.lastActivityAt")
                micon(color="grey" name="phonelink_ring")
                | Última actividad hace {{ patient.lastActivityAt | formatDistanceStrict }}
              .detail-item(v-else)
                micon(color="grey" name="phonelink_erase")
                | No tiene actividad
              .detail-item(v-if="patient.device && patient.device.hasNotificationsToken")
                micon(color="grey" name="notifications_active")
                | Notificaciones Activadas
              .detail-item(v-else)
                micon(color="grey" name="notifications_off")
                | Notificaciones desactivadas
              //- .detail-item(v-if="patient.passwordSetAt")
                | Contraseña configurada hace {{ patient.passwordSetAt | formatDistanceStrict }}

        .headline__actions
          //- button.button.button--blue(@click="modal = 'map'")
            micon(name="location_on").button__icon
            span.button__text Ver Mapa

          button.button.button--blue(v-if="publicGroup.length > 1" @click="showModal({modal: 'group'})")
            span.button__text Grupo Familiar

          button.button.button--blue(v-if="!activeEpisode && (isAdmin || isAssistant) && enableEpisodeCreation" @click="showModal({modal: 'open-episode'})")
            micon.button__icon(name="note_add")
            span.button__text Abrir Episodio
          router-link.button.button--blue(:to="{ name: 'patient-tasks' }")
            micon.button__icon(name="calendar")
            span.button__text Ver tareas
          router-link.button.button--blue(
            :to="{ name: 'assign-treatment-patient', params: { id: this.patientId } }"
          )
            micon.button__icon(name="add_circle_outline")
            span.button__text Editar Protocolos
          router-link.button.button--blue(v-if='this.isSalesEnabled && this.isDoctor' :to="{name: 'sales-list', params: {patient : this.patient}}")
            //- micon.button__icon(name='tickets')
            span.button__text Ver Catálogo de ventas
      
      patient-labels(v-model="patient.labels" :patientId="patient._id")

      .no-alerts(v-if="!Object.keys(vitals).length && !Object.keys(alerts).length")
        p No hay alertas

      .columns
        .top.rest-container
          form-box(:patient="patient" :activeEpisode="activeEpisode")
          indication-box(v-if='isIndicationsEnabled' :patient="patient" :activeEpisode="activeEpisode")
          attention-box(:patient="patient")
          activity-alert-box(:alerts="activityAlerts")
        .bottom.rest-container
          appointment-box(:appointments="appointments")
          notes-box(v-model="patient")
          alert-box(
            v-if="Object.keys(alerts).length",
            v-for="(alert, key) in alerts",
            @show-modal="showModal",
            :ref="key"
            :key="key",
            :type="key",
            :alerts="alert"
          )
          vital-box(
            v-for="(vital, key) in vitals",
            @show-modal="showModal",
            :ref="key"
            :key="key",
            :name="key",
            :vitals="vital"
            :patient="patient"
          )

      modal-task-graph(
        v-if="modal === 'task-graph'",
        :patientId="patientId",
        :name="selectedTask",
        :showDialogModal="showDialogModal",
        @close="closeModal"
      )
      modal-patient-tasks-list(
        v-if="modal === 'tasks-table'",
        :patientId="patientId",
        :name="selectedTask",
        :type="selectedType",
        :showDialogModal="showDialogModal",
        @close="closeModal"
      )
      modal-manual-add-task(
        v-if="modal === 'add-manual-task'",
        :newTask="selectedTask",
        :patientId="patientId",
        :showDialogModal="showDialogModal",
        @close="closeModal",
        @onSave="onSaveManualTask"
      )
      modal-patient-map(
        v-if="modal === 'map'",
        :newTask="selectedTask",
        :showDialogModal="showDialogModal",
        @close="closeModal"
      )
      modal-task-detail(
        v-if="modal === 'task-detail'",
        :task="selectedTask",
        :showDialogModal="showDialogModal",
        @close="closeModal"
      )
      modal-open-episode(
        v-if="modal === 'open-episode'",
        :patientId="patientId",
        :showDialogModal="showDialogModal",
        @close="closeModal"
        @openEpisode="openEpisode"
      )
      modal-group(
        v-if="modal === 'group'",
        :publicGroup="publicGroup"
        :patientId="patient._id"
        :showDialogModal="showDialogModal",
        @close="closeModal"
      )
      modal-patient-ecg-table(
        v-if="modal === 'ecg-table'",
        :selectedTask="selectedTask",
        :showDialogModal="showDialogModal",
        @close="closeModal"
      )
</template>

<style>
.headline {
  margin-bottom: 0;
}

.patient-details {
  margin-top: 15px;

  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr;
}

.columns > .box {
  margin-bottom: 0;
}
</style>
