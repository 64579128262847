<script>
import { TASKS_TYPES } from "@/services/constants";
import helperService from "@/services/helper";

export default {
  name: "AppointmentBox",

  data() {
    return {
      TASKS_TYPES,
    };
  },

  props: {
    appointments: {
      type: Array,
      required: true,
    },
  },

  methods: {
    getFullName(user) {
      return helperService.getFullName(user);
    },

    getAppointmentLabel(appointment) {
      return appointment.attentionType === "virtual"
        ? "Consulta virtual"
        : "Consulta Presencial";
    },
  },
};
</script>

<template lang="pug">
  .box.alert-box(:class="type")
    .header
      h1 Turnos
      .buttons

    .content
      .box(
        v-for="(appointment, index) in appointments",
        :key="appointment._id"
      )
        .data
          h4 {{ getAppointmentLabel(appointment) }}
          h4 Doctor: {{ getFullName(appointment.doctor) }}
          h4.date {{ appointment.startsAt | formatDateAndTime }}
</template>
