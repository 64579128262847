<script>
import Spinner from "@/components/ui/Spinner";
import paymentService from "@/services/payments";
import helperService from "@/services/helper";
import patientService from "@/services/patient";

export default {
  name: "SalesList",

  components: {
    Spinner,
  },

  data() {
    return {
      sales: [],
      selectedSale: {},
      patient: {},
      doctorId: this.$store.state.user.publicId,
      searchInput: "",
      showConfirmModal: false,
      isPosting: false,
      isLoading: false,
    };
  },

  created() {
    this.getServices();
    this.getPatient();
  },

  methods: {
    async getServices() {
      this.isLoading = true;
      try {
        const sales = await paymentService.get();
        this.sales = sales;
      } finally {
        this.isLoading = false;
      }
    },

    async getPatient() {
      const patient = await patientService.getById(this.$route.params.id);
      this.patient = patient;
    },

    getFullName(user) {
      return helperService.getFullName(user);
    },

    async sendPayment() {
      this.isPosting = true;
      const service = {
        patientId: this.$route.params.id,
        price: {
          amount: this.selectedSale.customPrice,
          currency:
            this.selectedSale.extension[1].valueString === "Mexican Peso"
              ? "MXN"
              : "",
        },
        serviceId: this.selectedSale.id,
        serviceDescription: this.selectedSale.name,
      };

      paymentService.post(service).finally(() => {
        this.isPosting = false;
        this.closeModal();
      });
    },

    openModal(sale) {
      if (!sale.customPrice) {
        sale.customPrice = sale.extension[0].valueDecimal;
      }
      this.selectedSale = sale;
      this.showConfirmModal = true;
    },

    closeModal() {
      this.selectedSale = {};
      this.showConfirmModal = false;
    },
  },
};
</script>

<template lang="pug">
  section.patient-sales
    header.headline
        .headline__title
          .back(@click="$router.back()")
              micon(name="arrow_back" width="30px" height="30px")
          .title
            h1
                | Catálogo de Ventas
        .headline__actions
            el-input(
                prefix-icon="el-icon-search"
                v-model="searchInput"
                placeholder="Buscar"
                :clearable="true"
            )
    ValidationObserver(v-slot="{handleSubmit}")
        .box
            .box__content--stretch
                table
                    thead
                        tr
                        th Nombre de práctica
                        th Clínica
                        th Dirección
                        th Precio sugerido
                        th Precio
                        th Moneda
                        th Acción
                    tbody
                        spinner(v-if="isLoading")
                        tr(v-else v-for="(sale, index) in sales" :key="sale.id")
                            td {{ sale.name }}
                            td {{ sale.location ? sale.location.name : 'N/A' }}
                            td {{ sale.location ? sale.location.address.street : 'N/A' }}
                            td 
                                ValidationProvider(vid="suggested_price")
                                    p $ {{ parseInt(sale.extension[0].valueDecimal) }}
                            td.customPrice
                                ValidationProvider(name="precio" :rules="{sale_price: {suggested_price: sales[index].extension[0].valueDecimal }}" v-slot="{ errors }")
                                    el-input.customPrice__value(:placeholder='parseFloat(sale.extension[0].valueDecimal)' v-model='sales[index].customPrice')
                                    span.has-error {{errors[0]}}
                            td 
                              p {{ sale.extension[1].valueString }}
                            td.actions
                                .actions-container
                                    el-button.cart(icon="el-icon-shopping-cart-2" :disabled="sale.price > sale.customPrice" circle @click="openModal(sale)")


    el-dialog(
        v-if="showConfirmModal"
        custom-class="event-detail-modal",
        :visible.sync="showConfirmModal",
        :before-close="closeModal"
        title='Detalle de la venta'
      )
        .view-task
            .metadata

              .form-item
                .description Paciente
                .response {{ getFullName(this.patient)}}

              .form-item(v-if='this.selectedSale.name')
                .description Servicio
                .response {{ this.selectedSale.name }}
              
              .form-item(v-if='this.selectedSale.location')
                .description Hospital
                .response {{ this.selectedSale.location.name }}

              .form-item
                .description Precio venta
                .response $ {{ this.selectedSale.customPrice }}

            el-button(
                type="primary"
                icon="el-icon-check"
                :loading="isPosting" 
                @click="sendPayment()"
            )
                | Aceptar
            el-button(
                type="danger"
                icon="el-icon-close"
                @click="closeModal"
            )
                | Cancelar

</template>

<style lang="scss" scoped>
.headline__title {
  display: flex;
  align-items: center;

  .back {
    cursor: pointer;
    margin-right: 10px;
  }
}

.customPrice {
  width: 10%;

  &__value {
    margin-top: 10px;
  }
}

.has-error {
  margin: 0;
  padding-top: 10px;
}

.cart {
  font-size: 18px;
}

.event-detail-modal {
  h2 {
    font-weight: bold;
    margin-bottom: 20px;
  }

  h3 {
    margin: 0 0 20px;
  }

  .form-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    .description {
      word-break: break-word;
      font-weight: bold;
      color: $primary;
      margin-bottom: 10px;
    }

    .response {
      margin-left: 20px;
      display: list-item;
      list-style-type: "→ ";
    }
  }
}
</style>
