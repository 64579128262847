<script>
import patientService from "@/services/patient";
import labelsService from "@/services/labels";

export default {
  name: "PatientLabels",

  props: {
    value: {
      type: Array,
      required: true,
    },
    patientId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      labels: [],
      selectedLabel: null,
    };
  },

  created() {
    labelsService.getLabels().then((labels) => {
      this.labels = labels;
      this.setDefaultOption();
    });
  },

  watch: {
    value() {
      this.setDefaultOption();
    },
  },

  methods: {
    showModal() {
      this.$modal.show("modal-add-label");
    },

    closeModal() {
      this.$modal.hide("modal-add-label");
    },

    updatePatient(value, patientId) {
      return patientService.update({
        _id: patientId,
        labels: value,
      });
    },

    async createLabel() {
      this.closeModal();

      const label = this.labels.find((l) => l._id === this.selectedLabel);
      if (this.value) {
        this.$emit("input", [...this.value, label]);
      } else {
        this.$set(this.value, 0, label);
      }

      await this.updatePatient([...this.value, label], this.patientId);
    },

    async removeLabel(label) {
      let filteredLabels = this.value.filter((l) => l._id !== label._id);
      this.$emit("input", filteredLabels);

      await this.updatePatient(filteredLabels, this.patientId);
    },

    isOptionDisabled(lableId) {
      return this.value && this.value.some((label) => label._id === lableId);
    },

    setDefaultOption() {
      const defaultOption = this.labels.find(
        (label) => !this.isOptionDisabled(label._id)
      );
      this.selectedLabel = defaultOption && defaultOption._id;
    },
  },
};
</script>

<template lang="pug">
.labels
  .tag(
    v-for="label in value",
    :key="label._id"
    :style="{ border: `1px solid ${label.color}` }"
  )
    | {{ label.name && label.name.replace('ń', 'ñ') }}
    span(@click="removeLabel(label)")
      micon.clear-icon(name="clear")

  .tag.add-label(@click="showModal()", v-tooltip="'Agregar etiqueta'")
    micon(name="add")

  modal(name="modal-add-label", height="auto", width="30%")
    .modal-header
      h2 Agregar etiqueta

    .modal-content
      el-select(
        v-model="selectedLabel",
        filterable,
        default-first-option,
      )
        el-option(
          v-for="option in labels",
          :key="option._id",
          :label="option.name",
          :disabled="isOptionDisabled(option._id)",
          :value="option._id"
        )

    .modal-footer
      button.button.button--blue.border(
        style="margin-left: auto",
        @click="createLabel",
        :disabled="!selectedLabel"
      ) Guardar
</template>

<style lang="scss" scoped>
.labels {
  margin: 10px 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .tag {
    display: inline-flex;
    white-space: nowrap;
    padding: 4px 4px 4px 8px;
    margin: 0 10px 5px 0;

    .clear-icon {
      cursor: pointer;
      width: 14px;
      height: 14px;
      margin-left: 5px;
      fill: $gainsboro;
      &:hover {
        fill: $primary;
      }
    }
  }

  .add-label {
    cursor: pointer;
    border-radius: 100%;
    width: 25px;
    min-width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    &:hover {
      border-color: $primary;
    }

    .micon {
      width: 19px;
      height: 19px;
    }
  }
}
</style>
