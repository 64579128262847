<script>
import { TASKS_TYPES } from "@/services/constants";
import AlertBadge from "@/components/ui/AlertBadge";

export default {
  name: "AlertBox",

  components: {
    AlertBadge,
  },

  data() {
    return {
      TASKS_TYPES,
    };
  },

  props: {
    type: String,
    alerts: Array,
  },

  methods: {
    shouldShowDetail(alert) {
      return (
        alert.description ||
        alert.type === "checkins" ||
        alert.type === "reports"
      );
    },

    showDetail(alert) {
      if (alert.description || alert.question || alert.type === "reports") {
        this.$emit("show-modal", { modal: "task-detail", task: alert });
      }
    },
  },
};
</script>

<template lang="pug">
  .box.alert-box(:class="type")
    .header
      h1 {{ TASKS_TYPES[type].name | capitalize }}
      .buttons
        router-link(:to="{ name: 'patient-tasks', query: { taskType: type } }")
          micon(name="calendar", v-tooltip="'Ver tareas'")
        a(@click="$emit('show-modal', { modal: 'tasks-table', task: type })")
          micon(name="list", v-tooltip="'Ver tabla de tareas'")

    .content
      .box(
        v-for="(alert, index) in alerts",
        :class="{ clickable: shouldShowDetail(alert) }",
        :key="alert._id"
        @click="showDetail(alert)"
      )
        .data
          h4(:class="{ underline: shouldShowDetail(alert) }") {{ alert.name }}
            micon(
                v-if="alert.files && alert.files.length",
                name="attach_file",
                height="20px",
                width="20px",
                v-tooltip="'Archivos Adjuntos'"
              )
          h4.date(v-if="alert.completedAt || alert.updatedAt")
            | Hace {{ alert.completedAt || alert.updatedAt | formatDistanceStrict }}
        .alert-type
          alert-badge(:alert="alert")
</template>
