import api from "./api";

const medicationService = {};
const medicationEndpoint = "/medications";

medicationService.getmedications = function (queryParams) {
  return api
    .get(medicationEndpoint, { params: queryParams })
    .then((res) => res.data);
};

export default medicationService;
