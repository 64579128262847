<script>
import Twilio from "twilio-video";
import videoRoomService from "@/services/videoRoom";

export default {
  name: "VideoRoom",

  props: {
    patientId: {
      type: String,
      require: true,
    },
    appointmentId: {
      type: String,
    },
  },

  data() {
    return {
      roomId: "",
      isLoading: false,
      isAudioEnabled: true,
      isVideoEnabled: true,
      room: "",
      state: "pending",
    };
  },

  created() {
    window.addEventListener("beforeunload", this.handleWindowClose, false);
  },

  beforeDestroy() {
    this.room && this.disconnect();
  },

  methods: {
    async createRoom() {
      try {
        this.state = "connecting";
        const { accessToken, roomId } = await videoRoomService.createRoom({
          patientId: this.patientId,
          appointmentId: this.appointmentId,
        });
        this.roomId = roomId;

        const room = await Twilio.connect(accessToken, {
          video: true, // YOU CAN ADD MORE SENTTINGS,
          audio: true,
        });

        this.room = room;
        this.state = "waiting";
      } catch (err) {
        console.log(err);
      }

      this.handleConnectedParticipant(this.room.localParticipant, true);
      this.room.participants.forEach(this.handleConnectedParticipant);
      this.room.on("participantConnected", this.handleConnectedParticipant);

      this.room.on(
        "participantDisconnected",
        this.handleDisconnectedParticipant
      );
    },

    handleConnectedParticipant(participant) {
      participant.tracks.forEach((trackPublication) => {
        this.handleTrackPublished(trackPublication, participant);
      });
      participant.on("trackPublished", (trackPublication) => {
        this.handleTrackPublished(trackPublication, participant);
      });
      const isLocalParticipant =
        participant.identity === this.$store.state.user._id;
      if (!isLocalParticipant) {
        this.state = "connected";
        participant.on("trackDisabled", (trackPublication) => {
          this.handleStatusTrackDisabled(trackPublication, participant);
        });
        participant.on("trackEnabled", (trackPublication) => {
          this.handleStatusTrackEnabled(trackPublication, participant);
        });
        participant.on("disconnected", () => {
          this.state = "disconnected";
        });
      }
    },

    handleDisconnectedParticipant(participant) {
      const trackContainerDom = document.getElementById(participant.identity);
      if (trackContainerDom) {
        trackContainerDom.remove();
      }
      participant.removeAllListeners();
    },

    handleTrackPublished(trackPublication, participant) {
      const isLocalParticipant =
        participant.identity === this.$store.state.user._id;

      let domContainer = "";

      if (isLocalParticipant) {
        domContainer = document.getElementById("localTrack");
      } else {
        domContainer = document.getElementById("remoteTrack");
      }

      function handleTrackSubscribed(track) {
        if (track.kind === "video" || track.kind === "audio") {
          const trackDom = track.attach();
          trackDom.id = `${participant.identity}_${track.kind}`;
          trackDom.style.maxHeight = "100%";
          trackDom.style.maxWidth = "100%";

          const trackContainerDom =
            document.getElementById(participant.identity) ||
            document.createElement("div");
          trackContainerDom.id = participant.identity;
          trackContainerDom.className = "track-container";

          trackContainerDom.append(trackDom);
          domContainer.append(trackContainerDom);
        }
      }

      function handleTrackUnsubscribed(track) {
        const existingTrackDom = document.getElementById(
          `${participant.identity}_${track.kind}`
        );
        if (existingTrackDom) {
          existingTrackDom.parentNode.removeChild(existingTrackDom);
        }
      }

      function handleStatusSubscribed(participant, track) {
        const statusModalDom = document.getElementById(
          `${participant.identity}_${track.kind}_status`
        );
        if (statusModalDom) {
          statusModalDom.parentNode.removeChild(statusModalDom);
        }
      }

      function handleStatusUnSubscribed(participant, track) {
        const trackContainerDom = document.getElementById(participant.identity);
        if (trackContainerDom) {
          const kindOffContainer = document.createElement("div");
          kindOffContainer.id = `${participant.identity}_${track.kind}_status`;
          kindOffContainer.classList.add(`${track.kind}-off-container`);
          if (track.kind === "video") {
            const camOffSVG =
              document.getElementsByClassName("button_camera_off");
            const camOffSVGClone = camOffSVG[0].firstChild.cloneNode(true);
            camOffSVGClone.classList.add("video-off");
            kindOffContainer.appendChild(camOffSVGClone);
            kindOffContainer.innerHTML += `<h4>El participante ha pausado la cámara.</h4>`;
          }
          if (track.kind === "audio") {
            const micOffSVG = document.getElementsByClassName("mic_off");
            const micOffSVGClone = micOffSVG[0].cloneNode(true);
            micOffSVGClone.classList.add("audio-off");
            kindOffContainer.appendChild(micOffSVGClone);
            trackContainerDom.append(kindOffContainer);
          }
          trackContainerDom.append(kindOffContainer);
        }
      }

      if (trackPublication.track) {
        handleTrackSubscribed(trackPublication.track);
      }

      trackPublication.on("subscribed", (track) => {
        handleStatusSubscribed(participant, track);
        handleTrackSubscribed(track);
      });
      trackPublication.on("unsubscribed", (track) => {
        handleTrackUnsubscribed(track);
        handleStatusUnSubscribed(participant, track);
      });
    },

    handleStatusTrackDisabled(track, participant) {
      const trackContainerDom = document.getElementById(participant.identity);
      if (trackContainerDom) {
        const kindOffContainer = document.createElement("div");
        kindOffContainer.id = `${participant.identity}_${track.kind}_status`;
        kindOffContainer.classList.add(`${track.kind}-off-container`);
        if (track.kind === "video") {
          const camOffSVG =
            document.getElementsByClassName("button_camera_off");
          const camOffSVGClone = camOffSVG[0].firstChild.cloneNode(true);
          camOffSVGClone.classList.add("video-off");
          kindOffContainer.appendChild(camOffSVGClone);
          kindOffContainer.innerHTML += `<h4>El participante ha pausado la cámara.</h4>`;
        }
        if (track.kind === "audio") {
          const micOffSVG = document.getElementsByClassName("mic_off");
          const micOffSVGClone = micOffSVG[0].cloneNode(true);
          micOffSVGClone.classList.add("audio-off");
          kindOffContainer.appendChild(micOffSVGClone);
        }
        trackContainerDom.append(kindOffContainer);
      }
    },

    handleStatusTrackEnabled(track, participant) {
      const statusModalDom = document.getElementById(
        `${participant.identity}_${track.kind}_status`
      );
      if (statusModalDom) {
        statusModalDom.parentNode.removeChild(statusModalDom);
      }
    },

    toggleVideoTrack() {
      this.isVideoEnabled = !this.isVideoEnabled;
      if (this.isVideoEnabled) {
        this.room.localParticipant.videoTracks.forEach(({ track }) => {
          track.enable();
        });
      } else {
        this.room.localParticipant.videoTracks.forEach(({ track }) => {
          track.disable();
        });
      }
    },

    toggleAudioTrack() {
      this.isAudioEnabled = !this.isAudioEnabled;
      if (this.isAudioEnabled) {
        this.room.localParticipant.audioTracks.forEach(({ track }) => {
          track.enable();
        });
      } else {
        this.room.localParticipant.audioTracks.forEach(({ track }) => {
          track.disable();
        });
      }
    },

    handleWindowClose() {
      if (this.room) {
        // number of miliseconds to hold before unloading page
        let waitingTime = 2 * 1000;
        let unloadingDate = new Date().getTime() + waitingTime;

        this.disconnect();

        // browser will hold unloading your page untill unloadingDate
        while (new Date().getTime() < unloadingDate) {} // eslint-disable-line
      }
    },

    disconnect() {
      videoRoomService.endRoom(this.roomId);
      this.room.participants.forEach((participant) => {
        this.handleDisconnectedParticipant(participant);
      });
      this.handleDisconnectedParticipant(this.room.localParticipant);
      this.room.disconnect();
      this.room = "";
      this.state = "ended";
    },
  },
};
</script>

<template lang="pug">
    .video-chat(v-else)
        .status-screen(v-show="!room")
          div(v-if="(state === 'ended' || state === 'cancel')")
            h2 La llamada ha finalizado.
          div(v-else)
            h2 ¿Todo listo para unirte?
            button.button.button--blue(@click="createRoom" :disabled="state!=='pending'") {{state==="connecting" ? 'Conectando...' : "Unirse"}}
        .status-screen(v-show="room && state !== 'connected'")
          div(v-if="state === 'waiting'")
            h2  Esperando a que el participante se una a la sala...
          div(v-else)
            h2 El participante se ha desconectado de la sala.
        #remoteTrack
        #localTrack
        .video-controls(v-if="this.room")
            button.button.toggle__button.button_mic(v-show="this.isAudioEnabled" @click="toggleAudioTrack")
                micon.mic(v-show="this.isAudioEnabled" name="mic")
            button.button.toggle__button.button_mic_off(v-show="!this.isAudioEnabled" @click="toggleAudioTrack")    
                micon.mic.mic_off(name="mic_off")
            button.button.toggle__button.button_phone(@click="disconnect")
                micon.phone(name="call_end")
            button.button.toggle__button.button_camera(v-show="this.isVideoEnabled" @click="toggleVideoTrack")
                micon.camera(v-show="this.isVideoEnabled" name="videocam")
            button.button.toggle__button.button_camera_off(v-show="!this.isVideoEnabled" @click="toggleVideoTrack")    
                micon.camera(name="videocam_off")
</template>

<style lang="scss">
.video-chat {
  background-color: black;
  height: 70vh;
  width: 63vw;
  max-height: 70vh;
  max-width: 100%;
  position: relative;
  overflow: hidden;

  .status-screen {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.3);
    color: white;

    h2 {
      color: white;
      margin-bottom: 10px;
    }

    .button {
      -webkit-box-shadow: -3px 5px 15px 5px rgba(0, 0, 0, 0.29);
      box-shadow: -3px 5px 15px 5px rgba(0, 0, 0, 0.29);
      border-radius: 10px;
      font-size: 16px;
      padding: 20px;
      margin: 0 auto;
    }
  }

  .status-track {
    position: absolute;
    top: 15px;
    left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: white;
    font-size: 22px;
  }

  #remoteTrack {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.3);

    h2 {
      color: white;
      margin-bottom: 10px;
    }

    .track-container {
      width: 100%;
      height: 100%;
      text-align: center;
      display: flex;
      justify-content: center;

      .audio-off-container {
        position: absolute;
        left: 10px;
        top: 10px;

        .audio-off {
          fill: white;
          height: 25px;
          width: 25px;
        }
      }

      .video-off-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .video-off {
          fill: white;
          height: 52px;
          width: 52px;
        }

        h4 {
          color: white;
          font-size: 22px;
          font-weight: 600;
        }
      }

      video {
        border-radius: 5px;
      }
    }
  }

  #localTrack {
    position: absolute;
    z-index: 3;
    width: 25vw;
    height: 15vh;
    right: 10px;
    bottom: 10px;

    .track-container {
      text-align: right;
      width: 100%;
      height: 100%;

      video {
        -webkit-box-shadow: -3px 5px 15px 5px rgba(0, 0, 0, 0.29);
        box-shadow: -3px 5px 15px 5px rgba(0, 0, 0, 0.29);
        border-radius: 5px;
      }
    }
  }

  .video-controls {
    position: absolute;
    z-index: 3;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%);
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 20px;

    .toggle__button {
      background-color: rgba(224, 224, 224, 0.3);
      border: none;
      border-radius: 50%;
    }

    .toggle__button:hover {
      opacity: 0.8;
    }

    .button_phone,
    .button_mic_off,
    .button_camera_off {
      background-color: rgb(234, 67, 53);
      border: none;

      svg {
        fill: white;
        color: white;
      }
    }

    .button_mic,
    .button_camera {
      svg {
        fill: white;
        color: white;
      }
    }
  }
}
</style>
