<script>
import { mapGetters } from "vuex";

import leave from "@/mixins/leave";

import fileUploadConfigService from "@/services/fileupload-config";
import segmentService from "@/services/segment";
import indicationService from "@/services/indication";
import medicationService from "@/services/medication";
import helperService from "@/services/helper";
import { s3Url } from "@/services/config";

import Spinner from "@/components/ui/Spinner";
import FileUpload from "@/components/ui/FileUpload";
import InputTag from "@/components/ui/InputTag";
import DiagnosisSelect from "@/components/ui/DiagnosisSelect";
import { INDICATIONS_TYPES, getIndicationForm } from "@/services/constants";

export default {
  name: "ModalIndication",
  mixins: [leave],

  components: {
    Spinner,
    FileUpload,
    InputTag,
    DiagnosisSelect,
  },

  props: {
    showDialogModal: {
      type: Boolean,
    },
    currentIndication: {
      type: Object,
    },
  },

  data() {
    return {
      isLoading: false,
      isMedicationLoading: false,
      isPosting: false,
      indicationsTypes: INDICATIONS_TYPES,
      indicationId: this.currentIndication._id || null,
      indication: {
        type: "",
        title: "",
        description: "",
        drugName: "",
        dosage: "",
        quantity: "",
        episodeId: this.currentIndication.episodeId,
        doctorId: "",
        patientId: this.currentIndication.patient,
        diagnosis: null,
        results: {
          description: "",
        },
        medications: [
          {
            medication: "",
            dosage: "",
            quantity: "",
          },
        ],
      },
      files: [],
      results: [],
      hasFiles: false,
      isFetchingIndication: false,
      isNewIndication: this.currentIndication.modalType === "create-indication",
      isShowed: this.showDialogModal,
      isCompleted: false,
      medications: [],
    };
  },

  computed: {
    ...mapGetters(["isAdmin", "isDoctor", "user"]),

    uploadMediaEndpoint() {
      return fileUploadConfigService.getIndicationFilesEndpoint(
        this.indicationId
      );
    },

    uploadResponseEndpoint() {
      return fileUploadConfigService.getIndicationResultEndpoint(
        this.indicationId
      );
    },

    indicationForm() {
      return getIndicationForm(this.indication.type)?.form;
    },

    canSave() {
      if (this.isNewIndication) {
        return true;
      } else {
        return (
          this.isResultAvailable(this.indication.type) && !this.isCompleted
        );
      }
    },
  },

  created() {
    if (!this.isNewIndication) {
      this.indication = this.currentIndication;

      this.isCompleted =
        this.currentIndication.results &&
        (!!this.currentIndication.results.description ||
          !!this.currentIndication.results.files.length);
    }
  },

  mounted() {
    document.addEventListener("keyup", this.escape);
  },

  beforeDestroy() {
    document.removeEventListener("keyup", this.escape);
  },

  methods: {
    findMedicationById(medicationId) {
      const medication = this.medications.find(
        (elem) => elem._id === medicationId
      );
      if (medication) {
        return medication.name;
      } else {
        return "Sin datos";
      }
    },

    escape(event) {
      if (event.keyCode == 27) this.close();
    },

    submit() {
      this.createOrUpdateIndication();
    },

    isResultAvailable(type) {
      return type === "images" || type === "lab" || type === "practices";
    },

    addResults() {
      this.indication.results = { description: "" };
    },

    indicationFiles(files) {
      const sorted = files.slice().sort((a, b) => {
        a.mimeType < b.mimeType;
      });
      sorted.forEach(function (file) {
        file.url = s3Url + "/" + file.key;
      });
      return sorted;
    },

    createOrUpdateIndication() {
      this.isPosting = true;
      this.showLeaveGuard = false;
      const eventName = "Indication Created";
      segmentService.track({ name: eventName });
      const service = this.isNewIndication
        ? indicationService.createIndication
        : indicationService.updateIndication;

      if (this.indication.type !== "medications") {
        delete this.indication.medications;
      }

      service(this.indication)
        .then((indication) => {
          this.indication = indication;
          this.indicationId = indication._id;

          if (this.hasFiles) {
            this.files.length && this.$refs.fileUpload.startUpload();
            this.results.length && this.$refs.resultUpload.startUpload();
          } else {
            this.endSubmit();
          }
        })
        .catch(() => {
          this.isPosting = false;
        });
    },

    endSubmit() {
      this.close();
      this.$bus.$emit("indication-created");
      this.isPosting = false;
      this.close();
    },

    getFileName(key = "") {
      return key.split("/").pop();
    },

    getDropdownOptions() {
      return {
        method: "put",
        uploadMultiple: true,
        maxFiles: 8,
        maxFilesize: 5,
        acceptedFiles: "image/*,application/pdf",
      };
    },

    getMedications(search) {
      this.medications = [];
      if (search.length < 3) {
        return;
      }
      this.isMedicationLoading = true;
      medicationService
        .getmedications({
          name: {
            $regex: `${search}`.toLowerCase(),
            $options: "i",
          },
        })
        .then((medications) => {
          this.medications = medications;
          this.isMedicationLoading = false;
        });
    },

    setDiagnosis(diagnosis) {
      this.indication.diagnosis = diagnosis;
    },

    onFilesAdded(file, media) {
      this.hasFiles = true;
      media.push({ originalFileName: file.name });
    },

    getFiles(media) {
      return media.slice();
    },

    onFileRemoved(file, media) {
      media.splice(
        media.findIndex(
          (addedFile) => addedFile.originalFileName === file.name
        ),
        1
      );
    },

    onFilesRemoved() {
      this.hasFiles = this.files.length && this.results.length;
    },

    onFileUploadSuccess() {
      const filesCompleted = this.$refs.fileUpload
        ? this.$refs.fileUpload.$refs.uploader.dropzone.files.every(
            (file) => file.status === "success" || file.manuallyAdded
          )
        : true;

      const resultsCompleted = this.$refs.resultUpload
        ? this.$refs.resultUpload.$refs.uploader.dropzone.files.every(
            (file) => file.status === "success" || file.manuallyAdded
          )
        : true;

      if (filesCompleted && resultsCompleted) {
        this.hasFiles = false;
        this.endSubmit();
      }
    },

    onMediaError() {
      this.isPosting = false;
    },

    close() {
      this.isPosting = false;
      this.isShowed = false;
      this.$emit("close");
    },

    getFullName(user) {
      return helperService.getFullName(user);
    },

    removeMedication(index) {
      if (this.indication.medications.length > 1) {
        this.indication.medications.splice(index, 1);
      }
    },

    addMedication() {
      const lastMedication =
        this.indication.medications[this.indication.medications.length - 1];

      if (
        !!lastMedication.medication &&
        !!lastMedication.dosage &&
        !!lastMedication.quantity
      ) {
        this.indication.medications.push({
          medication: "",
          dosage: "",
          quantity: "",
        });
      } else {
        this.$message.error(
          "No puedes agregar un nuevo medicamento sin terminar de configurar el anterior."
        );
      }
    },
  },
};
</script>

<template lang="pug">
ValidationObserver(v-slot="{handleSubmit}")
  form.modal(@submit.prevent="handleSubmit(submit)")
    header.modal__header
      h2.modal__title Indicacion {{ indication.title }}
      .modal__actions
        el-button(type="info" @click="close()") Cancelar
        el-button.border(v-if="canSave" type="primary" native-type="submit" :loading="isPosting") Guardar
    .modal__content(v-if="isFetchingIndication")
      spinner
    .modal__content
      .modal__block
        .modal__section
          .modal__sign.sign
            .sign__icon
              micon(name="assignment")
            h3.sign__title Detalle de la indicación
          article.modal__fields
            .modal__row
              fieldset.modal__field
                label.label Paciente
                p {{ getFullName(currentIndication.patient) }}
            .modal__row
              fieldset.modal__field
                label.label * Tipo
                ValidationProvider(name="Tipo" rules="required" v-slot="{ errors }")
                  el-select(v-model="indication.type" :disabled="!isNewIndication")
                    el-option(v-for="(type, typeKey) in indicationsTypes" :value="type.type" :label="type.name" :key="typeKey")
                  span.has-error {{errors[0]}}
            .modal__row(v-if="indication.type !=='medications'")
              fieldset.modal__field
                label.label Título
                  el-input(v-model="indication.title" :readonly="!isNewIndication")
            .modal__row(v-if="indication.type !=='medications'")
              fieldset.modal__field
                label.label Descripción
                  el-input(type="textarea" autosize v-model="indication.description" :readonly="!isNewIndication" v-if="indication.type !=='medications'")
            .modal__row(v-if="indication.type === 'medications'")
              fieldset.modal__field
                label.label Diagnóstico
                  el-input(type="textarea" autosize v-model="indication.diagnosisDescription" :readonly="!isNewIndication")
            .modal__row(v-for="(data,index) in indicationForm" :key="index" v-if="indication.type !=='medications'")
                fieldset.modal__field
                  label.label {{ data.friendlyName }}
                  el-input(:type='data.inputType' v-model='indication[data.label]' :value="data.defaultValue" :readonly="!isNewIndication")
            .modal__row(v-if='indication.type === "diagnosis"')
                fieldset.modal__field
                  label.label Diagnóstico
                    DiagnosisSelect(
                      :readonly="!isNewIndication"
                      :modelValue="indication.diagnosis"
                      @update:modelValue="diagnosis => indication.diagnosis = diagnosis"
                    )
      .modal__block(v-if="indication.type === 'medications'")
        .modal__section
          .modal__sign.sign
            .sign__icon
              micon(name="assignment")
            h3.sign__title Medicamentos
          article.modal__fields
            .medication(v-for="(elem, i) in indication.medications")
              .modal__row
                fieldset.modal__field 
                  label.label Droga
                  ValidationProvider(name="Droga" rules="required" v-slot="{ errors }")
                    el-select(v-if="isNewIndication"
                      v-model="elem.medication"
                      :readonly="!isNewIndication"
                      filterable
                      remote
                      :remote-method="getMedications"
                      :loading="isMedicationLoading"
                      multiple
                      placeholder="Ingrese al menos 3 letras"
                    )
                      el-option(v-for="medication in medications" :label="medication.name" :value="medication._id" :key="medication._id")
                    el-input(v-else :value="findMedicationById(elem.medication)" readonly)
                    span.has-error {{errors[0]}}
              .modal__row
                fieldset.modal__field 
                  label.label Dosis
                  ValidationProvider(name="Dosis" rules="required" v-slot="{ errors }")
                    el-input(v-model="elem.dosage" :readonly="!isNewIndication")
                    span.has-error {{errors[0]}}
              .modal__row
                fieldset.modal__field
                  label.label Cantidad
                  ValidationProvider(name="Cantidad" rules="required" v-slot="{ errors }")
                    el-input(v-model="elem.quantity" :readonly="!isNewIndication")
                    span.has-error {{errors[0]}}
              .modal__row
                fieldset.modal__field
                  label.label Via de administración
                  ValidationProvider(name="Via de administración" rules="required" v-slot="{ errors }")
                    el-input(v-model="elem.administration" :readonly="!isNewIndication")
                    span.has-error {{errors[0]}}
              .modal__row(v-if="isNewIndication")
                fieldset.modal__field
                  small
                    a(@click="removeMedication(i)") &times; Eliminar medicación {{ i + 1 }}
            .modal__row
              fieldset.modal__field
                el-button(@click="addMedication" v-if="isNewIndication") Agregar medicación

      .modal__block
        .modal__section
          .modal__sign.sign
            .sign__icon
              micon(name="assignment")
            h3.sign__title Archivos
          article.modal__fields
            .modal__row(v-if='isNewIndication')
              fieldset.modal__field
                label.label Adjuntar archivos (8 máx):
                file-upload(
                  ref="fileUpload",
                  :url="uploadMediaEndpoint",
                  :files="getFiles(files)", 
                  :dropzone-options="getDropdownOptions()"
                  @file-added="(file) => onFilesAdded(file, files)"
                  @file-removed="(file) => onFileRemoved(file, files)"
                  @files-removed="onFilesRemoved"
                  @fileupload-success="onFileUploadSuccess"
                  @fileupload-error="onMediaError"
                )
          article.modal__fields.modal__fields--small(v-if='indication.files && indication.files.length')
            .modal__row
              fieldset.modal__field
                label.label Archivos adjuntos:
                .attached-files
                  .file(
                    v-for="file in indicationFiles(indication.files)"
                    :class="{ image: file.mimeType.includes('image') }"
                  )
                    a(:href="file.url" target="_blank" rel="noopener noreferrer")
                      img(
                        v-if="file.mimeType.includes('image')"
                        :src="file.url"
                        alt="Archivo adjunto"
                      )
                      span(v-else) {{ getFileName(file.key) }}
      .modal__block(v-if="isResultAvailable(indication.type)")
        .modal__section
          .modal__sign.sign
            .sign__icon
              micon(name="assignment")
            h3.sign__title Resultado

          article.modal__fields
            .modal__row
              fieldset.modal__field
                label.label Descripción
                  el-input(v-model="indication.results.description" type="textarea" :readonly="isCompleted")
                  
            .modal__row
              fieldset.modal__field(v-if='isCompleted')
                label.label Archivos adjuntos:
                .attached-files(v-if="indication.results.files.length")
                  .results(v-for="file in indicationFiles(indication.results.files)")
                    .file.result(
                      :class="{ image: file.mimeType.includes('image') }"
                    )
                      .tag.alert-badge.card__tag.description(v-if="file.description") {{ getFileName(file.description) }}
                      a(:href="file.url" target="_blank" rel="noopener noreferrer")
                        img(
                          v-if="file.mimeType.includes('image')"
                          :src="file.url"
                          alt="Archivo adjunto"
                        )
                span(v-else) Sin adjuntos

              fieldset.modal__field(v-else)
                label.label Adjuntar archivos (8 máx):
                file-upload(
                  ref="resultUpload",
                  :url="uploadResponseEndpoint",
                  :dropzone-options="getDropdownOptions()"
                  :files="getFiles(results)",
                  :includesTable="true"
                  @file-added="(file) => onFilesAdded(file, results)"
                  @file-removed="(file) => onFileRemoved(file, results)"
                  @files-removed="onFilesRemoved"
                  @fileupload-success="onFileUploadSuccess"
                  @fileupload-error="onMediaError"
                )
</template>

<style lang="scss" scoped>
.medication {
  border-bottom: 1px solid $light-gray;
  margin-bottom: 10px;
}

.location {
  .micon {
    margin-right: 10px;
  }
}

.description {
  word-break: break-word;
  font-weight: bold;
  color: $primary;
  margin-bottom: 10px;
}

.response {
  margin-left: 20px;
  display: list-item;
  list-style-type: "→ ";
}

.attached-files {
  display: flex;
  flex-wrap: wrap;

  .file {
    width: 100%;
    margin: 0 15px 15px 0;

    &:not(.image) a::before {
      content: "→ ";
    }
  }
  .file.image {
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    box-shadow: 5px 4px 8px -3px rgba(0, 0, 0, 0.47);
    img {
      object-fit: cover;
      height: -webkit-fill-available;
    }
  }

  .results {
    width: 150px;

    .result {
      position: relative;

      .description {
        position: absolute;
        top: 2px;
      }
    }
  }
}

.view-task {
  padding: 1rem;
}
</style>
