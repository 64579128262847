<script>
import Spinner from "@/components/ui/Spinner";
import ModalAttention from "@/components/patient/dashboard/ModalAttention";

import { ATTENTION_RISKS } from "@/services/constants";
import { mapGetters } from "vuex";
import attentionService from "@/services/attention";
import AlertBadge from "@/components/ui/AlertBadge";
import { orderBy } from "lodash";

export default {
  name: "AttentionBox",

  props: {
    patient: {
      type: Object,
      required: true,
    },
    isCollapsable: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    AlertBadge,
    Spinner,
    ModalAttention,
  },

  data() {
    return {
      ATTENTION_RISKS,
      isLoading: false,
      attentions: [],
      filteredAttentions: [],
      isContentHidden: this.isCollapsable,
      selectedAttention: null,
      isAttentionModalVisible: false,
      filterModalShowed: false,
      attentionFilters: ATTENTION_RISKS.map((elem) => elem.value),
    };
  },

  created() {
    this.getData();
  },

  computed: {
    ...mapGetters(["user"]),
  },

  watch: {},

  methods: {
    async getData() {
      this.isLoading = true;
      try {
        const { docs: attentions } = await attentionService.getAttentions({
          query: { patient: this.patient._id, populate: "medicalSpecialty" },
        });

        this.attentions = this.sortAttentions(attentions);
        this.filteredAttentions = this.sortAttentions(attentions);
      } finally {
        this.isLoading = false;
      }
    },

    sortAttentions(attentions) {
      const order = ["urgent", "high", "medium", "low"];
      return orderBy(
        attentions,
        [
          ({ doctor }) => !!doctor,
          ({ risk }) => order.indexOf(risk),
          "updatedAt",
        ],
        ["asc", "asc", "desc"]
      );
    },

    closeAttentionModal() {
      this.isAttentionModalVisible = false;
      this.selectedAttention = null;
    },

    showAttentionModal(attention) {
      this.selectedAttention = attention;
      this.isAttentionModalVisible = true;
    },

    openModalFilter() {
      this.filterModalShowed = true;
    },

    closeFilterModal() {
      this.filterModalShowed = false;
    },

    getAttentionRisk(attention) {
      if (attention.completedAt) {
        return "Atendida";
      }

      const { label } = this.ATTENTION_RISKS.find(
        (elem) => elem.value === attention.risk
      );

      return `Riesgo: ${label}`;
    },

    getAttentionRiskColor(attention) {
      if (attention.completedAt) {
        return;
      }

      const { color } = this.ATTENTION_RISKS.find(
        (elem) => elem.value === attention.risk
      );

      return color;
    },

    toggleHideContent() {
      this.isContentHidden = !this.isContentHidden;
    },
  },
};
</script>

<template lang="pug">
  .box.form-box
    .header
      .expand(v-if="isCollapsable" @click='toggleHideContent()' :class="isContentHidden ? 'rotate' : ''")
        micon(name="expand_more")
      h1 Atenciones
      .buttons
        //- .filter.box__icon(@click="openModalFilter()")
          micon(v-show="!isContentHidden" name="filter" v-tooltip="'Filtrar'" )
    .content(:class="(isCollapsable && isContentHidden) ? 'hide-content' : '' ")
      .no-data(v-if="!isLoading && !filteredAttentions.length") No hay atenciones

      spinner(v-if="isLoading")

      .box.clickable(
        v-else 
        v-for="attention in filteredAttentions", 
        :key="attention._id",
        @click='showAttentionModal(attention)'
      )
        .data
          h4.underline Atención {{!!attention.completedAt ? 'completada' : 'solicitada'}}
          h4.date Hace {{ attention.createdAt || attention.updatedAt | formatDistanceStrict }}

        .alert-type
          .tag.alert-badge.card__tag(:class="getAttentionRiskColor(attention)") {{getAttentionRisk(attention)}}

      ModalAttention(
        v-if='isAttentionModalVisible'
        :attention='selectedAttention'
        :show-dialog-modal='isAttentionModalVisible' 
        @close='closeAttentionModal'
      )
      
      el-dialog.filter-dialog(
        title='Filtro'
        custom-class="event-detail-modal",
        :visible.sync="filterModalShowed"
        :before-close="closeFilterModal"
      )
        .filters
          h2 Filtrar por
          .attention-options.tags
            el-checkbox-group(
              v-model="attentionFilters"
              )
              el-checkbox(
                v-for="attention in ATTENTION_RISKS"
                :key="attention.value"
                :label="attention.value"
              ) {{ attention.label}}

          span(slot="footer")
            button.button.button--blue(@click="closeFilterModal()") Salir
</template>

<style lang="scss">
.form-box {
  .el-timeline-item {
    &:last-child {
      padding-bottom: 0;
    }
    &__timestamp {
      margin: 0;
    }
  }
}
</style>
<style lang="scss" scoped>
.form-box {
  .header {
    position: sticky;
    top: 0;
  }

  .expand {
    cursor: pointer;
    transition: all 0.4s ease-in-out;
    transform: rotate(180deg);
  }

  .rotate {
    transform: rotate(0deg);
  }

  .content {
    max-height: 400px;
    visibility: visible;
    transition: all 0.4s ease-in-out;

    &.hide-content {
      visibility: hidden;
      max-height: 0;
      padding-top: 0;
      padding-bottom: 0;
      transition: all 0.4s ease-in-out;
      overflow: hidden;
    }

    .box {
      flex-direction: column;
      margin: 0;
      align-items: flex-start;
      .micon {
        margin-left: 5px;
      }
    }
  }
}
</style>
