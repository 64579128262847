<script>
import helperService from "@/services/helper";

export default {
  name: "Group",

  data() {
    return {
      isLoading: false,
      group: [],
    };
  },

  props: {
    publicGroup: {
      type: Array,
      required: true,
    },
    patientId: {
      type: String,
      required: true,
    },
    showDialogModal: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },

    getFullName(patient) {
      return helperService.getFullName(patient);
    },

    isRedirectAllowed(doctorId) {
      // One doctor should not see another's doctor patient data
      if (this.$store.state.user.role === "Doctor") {
        return this.$store.state.user._id === doctorId;
      }
      // The rest of the roles should be able to see everything
      return true;
    },

    goToPatientDashboard(id, doctorId) {
      if (this.isRedirectAllowed(doctorId)) {
        this.$router.push({
          name: "patient-dashboard",
          params: { id },
        });
        this.close();
      }
    },
  },
};
</script>

<template lang="pug">
  el-dialog(v-if="publicGroup" name="modal-public-group", :visible.sync="showDialogModal", :before-close="close", title="Grupo Familiar")
    table
      thead
        tr
          th Paciente
          th Número de Socio
          th Doctor
      tbody(v-for="(groupMember, index) in publicGroup" :key="groupMember._id")
        tr(v-if="groupMember._id !== patientId" @click="goToPatientDashboard(groupMember._id, groupMember.doctors[0]._id)" :style="isRedirectAllowed(groupMember.doctors[0]._id) ? 'cursor:pointer' : 'cursor:default'")
          td {{ getFullName(groupMember) }}
          td {{ groupMember.patient.medicalInsurance.number }}
          td {{ getFullName(groupMember.doctors[0]) }}
    
</template>
